<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30 py-20' : 'p-10'}`">
    <span :class="`font-bold center ${isMobile ? 'mt-3 font-25' : 'mt-5 font-40'}`">{{ $t('terms_service') }}</span>
    <div :class="`d-flex flex-column ${isWide ? 'm-30 font-25' : isMobile ? 'font-15' : 'm-10 font-20'}`">
      <span :class="`font-bold ${isWide ? 'font-30' : 'font-20'}`">Willkommen bei EVENTBOX!</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Bitte lesen Sie die folgenden, für jeden Besucher der <a href="https://eventboxde.com" target="_blank">Website</a>, <a :href="GOOGLE_PLAY_URL" target="_blank">Android</a> or <a :href="APP_STORE_URL" target="_blank">iOS</a> APPs (im Folgenden <b>"EVENTBOX"</b> genannt) geltenden Nutzungsbedingungen durch.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Durch Nutzung dieser EVENTBOX erklären Sie konkludent Ihr Einverständnis mit diesen Nutzungsbedingungen.</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Der Inhaber der EVENTBOX behält sich das Recht vor, die Informationen auf dieser EVENTBOX jederzeit ohne vorherige Ankündigung zu ändern, zu streichen oder zu ergänzen.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">1. Geltungsbereich</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Die nachfolgenden Bedingungen gelten für die Nutzung der <a href="https://eventboxde.com" target="_blank">Website</a>, <a :href="GOOGLE_PLAY_URL" target="_blank">Android</a> or <a :href="APP_STORE_URL" target="_blank">iOS</a> APPs (nachfolgend "EVENTBOX"). Für die Nutzung der EVENTBOX ist wichtig, dass Sie als Nutzer/in die nachfolgenden Bestimmungen akzeptieren.
        </li>
        <li>
          Durch die Nutzung unserer EVENTBOX sind Sie mit den Nutzungsbedingungen unserer EVENTBOX einverstanden. Durch Ihr Einverständnis garantieren Sie uns, dass Sie keine Beiträge erstellen werden, die gegen die Nutzungsbedingung verstoßen.
        </li>
        <li>
          Gegenstand von Eventbox ist Folgendes: E-Tickets für Events
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">2. Kontaktdaten und rechtliche Hinweise</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Sollten Sie Fragen bezüglich unserer EVENTBOX haben, können Sie uns unter folgenden Kontaktdaten erreichen:</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Eventbox</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Karl-Marr-Str.1</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">81479 München</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">E-Mail: <a href="mailto:info@eventboxde.com">info@eventboxde.com</a></span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Geschäftsführerin: Buket Burc</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">3. Verfügbarkeit der EVENTBOX</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Eventbox hat eine Verfügbarkeit von 24 Stunden am Tag. Es kann jedoch vorkommen, dass es aufgrund von Wartungen, die für das Systems erforderlich sind, zu Unterbrechungen der Verfügbarkeit kommt. Unterbrechungen der Verfügbarkeit können unter Anderem aufgrund höherer Gewalt oder anderen, von Eventbox nicht zu vertretenden Ursachen, wie zum Beispiel Vorsatz oder grobe Fahrlässigkeit vorkommen.
        </li>
        <li>
          Eventbox weist darauf hin:
          <ul :class="isMobile ? 'ml-5' : 'ml-10'">
            <li>
              dass es technisch unmöglich ist, die Webseite frei von Fehlern jedweder Art zur Verfügung zu stellen und dass Eventbox deshalb keinerlei Verantwortung dafür übernimmt,
            </li>
            <li>
              dass Fehler zur zeitweisen Abschaltung der EVENTBOX führen können,
            </li>
            <li>
              dass die Verfügbarkeit dieser Webseiten von außerhalb des Einflussbereichs von Eventbox liegenden Bedingungen und Leistungen abhängig ist, wie z.B. den Übertragungskapazitäten und Telefonverbindungen zwischen den einzelnen Beteiligten. In diesen Bereich fallende Störungen haben wir nicht zu verantworten.
            </li>
          </ul>
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">4. Diskussionsforen</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Soweit Eventbox zeitweise oder dauerhaft auf ihren Webseiten Diskussionsforen anbietet, wird von den Teilnehmern der Diskussionsforen erwartet, dass sie die üblichen Kommunikationsregeln wie gegenseitigen Respekt beachten. Besucher dürfen keine beleidigenden, eventuell herabwürdigenden, unflätigen, anstößigen, diffamierenden oder obszönen Materialien oder Materialien, die die geistigen Eigentumsrechte Dritter verletzen, verbreiten oder veröffentlichen.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">5. Elektronische Kommunikation</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Wenn Sie einen Dienst von Eventbox nutzen oder E-Mails, Textnachrichten oder andere Mitteilungen von Ihrem Computer oder Ihrem mobilen Gerät an uns senden, kommunizieren Sie mit uns elektronisch. Wir werden mit Ihnen auf verschiedene Art und Weise elektronisch kommunizieren, z.B. über E-Mail, Textnachrichten oder auch durch die Veröffentlichung elektronischer Nachrichten oder sonstiger Kommunikation auf unserer EVENTBOX oder im Rahmen sonstiger Dienste von Eventbox. Für vertragliche Zwecke stimmen Sie zu, elektronische Kommunikation von uns zu erhalten und dass alle Zustimmungen, Benachrichtigungen, Veröffentlichungen und andere Kommunikation, die wir Ihnen elektronisch mitteilen insofern keine Schriftform erfordern, es sei denn zwingend anzuwendende gesetzliche Vorschriften erfordern eine andere Form der Kommunikation.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">6. Urheberrecht und Datenbankrechte</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Der gesamte Inhalt, der in einem Dienst von Eventbox enthalten oder durch ihn bereitgestellt wird, wie Text, Grafik, Logos, Button-Icons, Bilder, Audio-Clips, digitale Downloads und Datensammlungen, ist Eigentum von Eventbox oder von Dritten, die Inhalte zuliefern oder auf der EVENTBOX bereitstellen und ist durch deutsches Urheberrecht und Datenbankrecht geschützt.
        </li>
        <li>
          Auch der Gesamtbestand der Inhalte, der in einem Dienst von Eventbox enthalten oder durch ihn bereitgestellt wird, ist ausschließliches Eigentum von Eventbox und ist durch deutsches Urheberrecht und Datenbankrecht geschützt.
        </li>
        <li>
          Sie dürfen ohne unsere ausdrückliche schriftliche Zustimmung nicht Teile eines Dienstes von Eventbox systematisch extrahieren und/oder wiederverwenden. Insbesondere dürfen Sie ohne die ausdrückliche schriftliche Zustimmung von https://eventboxde.com kein Data Mining, keine Robots oder ähnliche Datensammel- und Extraktionsprogramme einsetzen, um irgendwelche wesentlichen Teile eines Dienstes von Eventbox zur Wiederverwendung zu extrahieren (gleichgültig ob einmalig oder mehrfach). Sie dürfen ferner ohne die ausdrückliche schriftliche Zustimmung von https://eventboxde.com keine eigene Datenbank herstellen und/oder veröffentlichen, die wesentliche Teile eines Dienstes von Eventbox beinhaltet.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">7. Patente</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Alle Patente die auf Eventbox angemeldet sind, sind auch auf alle Dienste und sonstige Produkte von Eventbox anwendbar. Es kann vorkommen, dass die jeweiligen Patente unter einem oder mehreren Patenten betrieben werden.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">8. Urheberrecht und Datenbankrechte</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Der Zugang zu einigen (kostenpflichtigen) Diensten der EVENTBOX, setzt die Erstellung eines Kontos voraus.
        </li>
        <li>
          Die Erstellung eines Kontos ist nur voll geschäftsfähigen Personen gestattet, denen das Anmeldeformular auf der EVENTBOX https://eventboxde.com bereit gestellt wird.
        </li>
        <li>
          Der Nutzer verpflichtet sich dazu, keine falschen Angaben zu seiner Person zu machen. Des Weiteren verpflichtet sich der Nutzer dazu, seine Angaben regelmäßig zu kontrollieren, um die Richtigkeit dieser zu gewährleisten.
        </li>
        <li>
          Wenn Sie einen Dienst von Eventbox nutzen, sind Sie für die Sicherstellung der Vertraulichkeit Ihres Kontos, Passworts und für die Beschränkung des Zugangs zu Ihrem Computer und Ihren mobilen Geräten verantwortlich. Soweit unter anwendbarem Recht zulässig, erklären Sie sich damit einverstanden, für alle Aktivitäten verantwortlich zu sein, die über Ihr Konto oder Passwort vorgenommen werden. Sie sollten alle erforderlichen Schritte unternehmen, um sicherzustellen, dass Ihr Passwort geheim gehalten und sicher aufbewahrt wird und Sie sollten uns unverzüglich informieren, wenn Sie Anlass zur Sorge haben, dass ein Dritter Kenntnis von Ihrem Passwort erlangt hat oder das Passwort unautorisiert genutzt wird oder dies wahrscheinlich ist. Sie sind dafür verantwortlich, sicherzustellen, dass Ihre Angaben an uns korrekt und vollständig sind und dass Sie uns von jeglichen Änderungen hinsichtlich der von Ihnen gegebenen Informationen in Kenntnis setzen. Sie können einige der Informationen, die Sie uns gegeben haben, auf unserer EVENTBOX einsehen und aktualisieren.
        </li>
        <li>
          Der Nutzer kann seinen Benutzernamen und sein Passwort nachträglich jederzeit ändern.
        </li>
        <li>
          Sie dürfen einen Dienst von Eventbox nicht in einer Weise verwenden, die dazu geeignet ist, die Dienste oder den Zugang von Eventbox zu unterbrechen, zu beschädigen oder in sonstiger Art zu beeinträchtigen.
        </li>
        <li>
          Des Weiteren dürfen Sie die Dienste von Eventbox nicht für betrügerische oder in Verbindung mit einer Straftat, rechtswidrigen Aktivitäten, Belästigungen oder Unannehmlichkeiten verwenden.
        </li>
        <li>
          Wir behalten uns das Recht vor, Ihnen die Dienste auf der EVENTBOX vorzuenthalten oder Mitgliedskonten zu schließen. Das gilt insbesondere für den Fall, dass Sie gegen anwendbares Recht, vertragliche Vereinbarungen oder unsere Richtlinien verstoßen.
        </li>
        <li>
          Die Nutzer dieser EVENTBOX können ihr Konto jederzeit löschen, indem Sie eine entsprechende Nachricht über das Kontaktformular abschicken. Das Konto des Nutzers wird unverzüglich gelöscht, sobald der Nutzer das Formular ausgefüllt und abgeschickt hat.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">9. Ansprüche aus Immaterialgüterrechten</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Eventbox respektiert die Immaterialgüterrechte Dritter. Wenn Sie der Auffassung sind, dass Ihre Immaterialgüterrechte in einer Art genutzt wurden, der Anlass zur Befürchtung einer Verletzung gibt, folgen Sie bitte unserem Verfahren zur Mitteilung an www.eventboxde.com über eine Rechtsverletzung.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">10. Haftung</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Wir bemühen uns stets sicherzustellen, dass die Dienste von Eventbox ohne Unterbrechungen verfügbar und Übermittlungen fehlerfrei sind. Durch die Beschaffenheit des Internets kann dies jedoch nicht garantiert werden. Auch Ihr Zugriff auf die Dienste von Eventbox kann gelegentlich unterbrochen oder beschränkt sein, um Instandsetzungen, Wartungen oder die Einführung neuer Einrichtungen zu ermöglichen. Wir versuchen die Häufigkeit und Dauer jeder dieser vorübergehenden Unterbrechungen oder Beschränkungen zu begrenzen.
        </li>
        <li>
          Eventbox haftet unbeschränkt, soweit die Schadensursache auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von Eventbox oder eines gesetzlichen Vertreters oder Erfüllungsgehilfen von Eventbox beruht.
        </li>
        <li>
          Ferner haftet Eventbox für die leicht fahrlässige Verletzung von wesentlichen Pflichten. Wesentlich sind Pflichten, deren Verletzung die Erreichung des Vertragszwecks gefährdet oder deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung Sie regelmäßig vertrauen. In diesem Fall haftet Eventbox jedoch nur für den vorhersehbaren, vertragstypischen Schaden. Eventbox haftet nicht für die leicht fahrlässige Verletzung anderer als der in den vorstehenden Sätzen genannten Pflichten.
        </li>
        <li>
          Die vorstehenden Haftungsbeschränkungen gelten nicht bei Verletzung von Leben, Körper und Gesundheit, für einen Mangel nach Übernahme einer Garantie für die Beschaffenheit des Produktes und bei arglistig verschwiegenen Mängeln. Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.
        </li>
        <li>
          Soweit die Haftung von Eventbox ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung von Arbeitnehmern, Vertretern und Erfüllungsgehilfen.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">11. Links zu anderen EVENTBOX</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Für Links, die nicht von der Eventbox betrieben werden und sich auf deren Webseite befinden, haben wir keinerlei Möglichkeit, den Inhalt dieser Webseite zu kontrollieren, da diese völlig unabhängig von uns ist.
        </li>
        <li>
          Aus diesem Grund übernehmen wir keinerlei Verantwortung für die Inhalte dieser Webseiten und die Folgen ihrer Verwendung durch die Besucher dieser. Das Aufrufen aller über Links erreichbaren Webseiten geschieht auf eigene Gefahr. Es erfolgt kein gesonderter Hinweis, wenn Benutzer die EVENTBOX verlassen. Wir bitten Sie aber, uns umgehend auf rechtswidrige und zweifelhafte Inhalte der verlinkten EVENTBOX aufmerksam zu machen.
        </li>
        <li>
          Andere Webseiten verfügen möglicherweise über einen Link zu den Webseiten von der Eventbox. Ein solcher Link erfordert unsere vorherige Zustimmung.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">12. Datenschutz</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Es kann vorkommen, dass Daten und Informationen der Besucher und Nutzer (Datum, Uhrzeit, aufgerufene Seite) über den Zugriff auf dem Server gespeichert werden. Wir weisen darauf hin, dass - ohne Einwilligung - keine personenbezogenenen (z. B. Name, Anschrift oder E-Mail-Adresse) Daten gespeichert werden.
        </li>
        <li>
          Sollten personenbezogene Daten erhoben werden, verpflichten wir uns dazu, das vorherige Einverständnis des Nutzers der EVENTBOX einzuholen. Wir verpflichten uns dazu, keine Daten an Dritte weiterzugeben, es sei denn der Besucher oder Nutzer willigt vorher ein.
        </li>
        <li>
          Wir weisen darauf hin, dass die Übertragung von Daten im Internet (z. B. per E-Mail) Sicherheitslücken aufweisen kann. Demnach kann ein fehlerfreier und störungsfreier Schutz der Daten Dritter nicht vollständig gewährleistet werden. Diesbezüglich ist unsere Haftung ausgeschlossen.
        </li>
        <li>
          Dritte sind nicht dazu berechtigt, Kontaktdaten für gewerbliche Aktivitäten zu nutzen, sofern der Anbieter den betroffenen Personen vorher eine schriftliche Einwilligung erteilt hat.
        </li>
        <li>
          Der Nutzer hat das Recht auf Auskunft. Sie haben jederzeit das Recht, von uns über den Sie betreffenden Datenbestand vollständig und unentgeltlich Auskunft zu erhalten.
        </li>
        <li>
          Des Weiteren besteht ein Recht auf Berichtigung/Löschung von Daten/ Einschränkung der Verarbeitung für den Nutzer.
        </li>
        <li>
          Weitere Angaben werden separat in der Datenschutzrichtlinie gemacht.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">13. Cookies</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Zur Anzeige des Produktangebotes kann es vorkommen, dass wir Cookies einsetzen. Bei Cookies handelt es sich um kleine Textdateien, die lokal im Zwischenspeicher des Internet-Browsers des Seitenbesuchers gespeichert werden.
        </li>
        <li>
          Zahlreiche Internetseiten und Server verwenden Cookies. Viele Cookies enthalten eine sogenannte Cookie-ID. Eine Cookie-ID ist eine eindeutige Kennung des Cookies. Sie besteht aus einer Zeichenfolge, durch welche Internetseiten und Server dem konkreten Internetbrowser zugeordnet werden können, in dem das Cookie gespeichert wurde. Dies ermöglicht es den besuchten Internetseiten und Servern, den individuellen Browser der betroffenen Person von anderen Internetbrowsern, die andere Cookies enthalten, zu unterscheiden. Ein bestimmter Internetbrowser kann über die eindeutige Cookie-ID wiedererkannt und identifiziert werden.
        </li>
        <li>
          Durch den Einsatz von Cookies kann den Nutzern dieser Internetseite nutzerfreundlichere Services bereitstellen, die ohne die Cookie-Setzung nicht möglich wären.
        </li>
        <li>
          Wir weisen Sie darauf hin, dass einige dieser Cookies von unserem Server auf Ihr Computersystem überspielt werden, wobei es sich dabei meist um so genannte sitzungsbezogene Cookies handelt. Sitzungsbezogene Cookies zeichnen sich dadurch aus, dass diese automatisch nach Ende der Browser-Sitzung wieder von Ihrer Festplatte gelöscht werden. Andere Cookies verbleiben auf Ihrem Computersystem und ermöglichen es uns, Ihr Computersystem bei Ihrem nächsten Besuch wieder zu erkennen (sog. dauerhafte Cookies).
        </li>
        <li>
          Sie können der Speicherung von Cookies widersprechen, hierzu steht Ihnen ein Banner zu Verfügung dem Sie widersprechen/annehmen können.
        </li>
        <li>
          Selbstverständlich können Sie Ihren Browser so einstellen, dass keine Cookies auf der Festplatte abgelegt werden bzw. bereits abgelegte Cookies wieder gelöscht werden. Die Anweisungen bezüglich der Verhinderung sowie Löschung von Cookies können Sie der Hilfefunktion Ihres Browsers oder Softwareherstellers entnehmen.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">14. Änderungen der Nutzungsbedingugnen</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Wir behalten uns das Recht vor, Änderungen an Diensten von Eventbox, Regelwerken, Bedingungen einschließlich dieser Nutzungsbedingungen jederzeit vorzunehmen. Sie unterliegen den Allgemeinen Geschäftsbedingungen, Vertragsbedingungen und Nutzungsbedingungen, die zu dem Zeitpunkt in Kraft sind, an dem Sie die Dienste von Eventbox nutzen. Falls eine dieser Bedingungen für unwirksam, nichtig oder aus irgendeinem Grund für undurchsetzbar gehalten wird, gilt diese Regelung als abtrennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit aller verbleibenden Regelungen nicht.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">15. Kein Verzicht</span>
      <span :class="isWide ? 'mt-10' : 'mt-5'">Wenn Sie diese Nutzungsbedingungen verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Nutzungsbedingungen verletzen, Gebrauch zu machen.</span>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">16. Gerichtsstand und anwendbares Recht</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Für Meinungsverschiedenheiten und Streitigkeiten anlässlich dieses Vertrages gilt ausschließlich das Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Kaufrechts.
        </li>
        <li>
          Alleiniger Gerichtsstand bei Bestellungen von Kaufleuten, juristischen Personen des öffentlichen Rechts oder öffentlich-rechtlichen Sondervermögen ist der Sitz des Anbieters.
        </li>
      </ol>

      <span :class="`font-bold ${isWide ? 'mt-20 font-30' : 'mt-10 font-20'}`">17. Schlussbestimmungen</span>
      <ol :class="isMobile ? 'ml-5' : 'ml-10'">
        <li>
          Die Vertragssprache ist deutsch.
        </li>
        <li>
          Wenn Sie diese allgemeinen Nutzungsbedingungen verletzen und wir unternehmen hiergegen nichts, sind wir weiterhin berechtigt, von unseren Rechten bei jeder anderen Gelegenheit, in der Sie diese Nutzungsbedingungen verletzen, Gebrauch zu machen.
        </li>
        <li>
          Wir behalten uns das Recht vor, Änderungen an unserer EVENTBOX, Regelwerken, Bedingungen einschließlich dieser Nutzungsbedingungen jederzeit vorzunehmen. Falls eine Regelung in diesen Nutzungsbedingungen unwirksam, nichtig oder aus irgendeinem Grund undurchsetzbar ist, gilt diese Regelung als abtrennbar und beeinflusst die Gültigkeit und Durchsetzbarkeit der verbleibenden Regelungen nicht.
        </li>
        <li>
          Die Unwirksamkeit einer Bestimmung berührt die Wirksamkeit der anderen Bestimmungen aus dem Vertrag nicht. Sollte dieser Fall eintreten, soll die Bestimmung nach Sinn und Zweck durch eine andere rechtlich zulässige Bestimmung ersetzt werden, die dem Sinn und Zweck der unwirksamen Bestimmung entspricht.
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    }
  }
};
</script>